import { useEffect } from 'react'
import styled from '@emotion/styled'
import BrellaLogoWithText from '@SVG/brella-logo.svg'
import { Divider } from 'antd'

import SignInBox from './SignInBox'

const AuthContainer = () => {
  useEffect(() => {
    document.title = 'Sign in | Brella'
  }, [])

  return (
    <Container data-test={'auth-container'}>
      <BrellaLargeLogo src={BrellaLogoWithText} />
      <Divider />
      <Header>{'Welcome to Brella Sponsor Panel'}</Header>
      <SubHeader>
        {'Create a new account or access your existing one by continuing with an option below'}
      </SubHeader>
      <Suggestion>
        {'We suggest using the email address you use at work.'}
      </Suggestion>
      <SignInBox withAnimation={false} />
      <Divider />
      <InfoContainer>
        <InfoText>
          {'Signing in means you accept our '}
          <a href={'https://www.brella.io/terms-and-conditions/'} target={'_blank'} rel={'noopener noreferrer'}>
            {'Terms & Privacy Policy'}
          </a>
        </InfoText>
        <InfoText>
          {'Brella is the world\'s leading '}
          <a href={'https://www.brella.io'} target={'_blank'} rel={'noopener noreferrer'}>
            {'event networking software'}
          </a>
        </InfoText>
        <InfoText>
          {'Have questions? '}
          <a href={'https://help.brella.io/en/kb-tickets/new'} target={'_blank'} rel={'noopener noreferrer'}>
            {'Contact our support'}
          </a>
        </InfoText>
      </InfoContainer>
    </Container>
  )
}

const Container = styled('div')({
  padding: '3em 0',
  margin: '0 auto',
  textAlign: 'center',
  maxWidth: '340px'
})

const Header = styled('h3')({
  fontSize: '17px',
  lineHeight: '22px',
  textAlign: 'left',
  letterSpacing: '-0.41px'
})

const SubHeader = styled('h4')({
  color: '#3C3C4399',
  textAlign: 'left',
  fontWeight: '300',
  fontSize: '13px',
  lineHeight: '19.5px',
  letterSpacing: '-0.08px'
})

const Suggestion = styled('h4')({
  color: '#3C3C4399',
  fontWeight: '300',
  fontSize: '11px',
  textAlign: 'left',
  paddingTop: '30px',
  paddingBottom: '20px'
})

const BrellaLargeLogo = styled('img')({
  height: 20
})

const InfoContainer = styled('div')({
  marginTop: 32,
  textAlign: 'center'
})

const InfoText = styled('p')({
  fontSize: 12,
  fontWeight: '400',
  color: '#8C8C8C',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
  marginBottom: 6,

  a: {
    color: '#8C8C8C',
    textDecoration: 'underline',

    ':hover': {
      color: '#000'
    }
  }
})

export default AuthContainer
